import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { port } from '../../App'
import InfoButton from '../SettingComponent/InfoButton'
import { Modal } from 'react-bootstrap'

const HROverviewReport = () => {
    let [data, setData] = useState()
    let [modalData, setModalData] = useState()
    let getData = () => {
        axios.get(`${port}/root/DisplayEmployeeCelebrations`).then((response) => {
            console.log(response.data, 'celeration');
            setData(response.data)
        }).catch((error) => {
            console.log(error);
        })
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <div>
            <main className=' container flex justify-around  flex-wrap gap-3 
            mx-auto min-h-[30vh] bgclr rounded my-5 p-3 ' >
                <section onClick={() => setModalData({ data: [...data.today_birthdays], eventName: 'BirthDay' })}
                    className='flex flex-col gap-3 relative p-3 w-[20rem] items-center bg-slate-50 rounded cursor-pointer hover:bg-blue-50 duration-300  '>
                    <span className='absolute top-2 right-2 ' >
                        <InfoButton content="Birthday date for the one week period." />
                    </span>
                    <h6 className=' ' >
                        BirthDays
                    </h6>
                    <p className='text-3xl sm:text-5xl fw-semibold ' >
                        {data && data.today_birthdays && data.today_birthdays.length}
                    </p>
                </section>
                <section onClick={() => setModalData({ data: [...data.today_work_anniversaries], eventName: 'Work Anniversary' })}
                    className='flex flex-col gap-3 relative p-3 w-[20rem] items-center bg-slate-50 rounded cursor-pointer hover:bg-blue-50 duration-300  '>
                    <span className='absolute top-2 right-2 ' >
                        <InfoButton content="Work anniversary for the one week period." />
                    </span>
                    <h6 className=' ' >
                        Work Anniversary
                    </h6>
                    <p className='text-3xl sm:text-5xl fw-semibold ' >
                        {data && data.today_work_anniversaries && data.today_work_anniversaries.length}
                    </p>
                </section>
                <section onClick={() => setModalData({ data: [...data.upcoming_holidays], eventName: 'Upcoming Holidays' })}
                    className='flex flex-col gap-3 relative p-3 w-[20rem] items-center bg-slate-50 rounded cursor-pointer hover:bg-blue-50 duration-300  '>
                    <span className='absolute top-2 right-2 ' >
                        <InfoButton content="Holiday date for the one week period." />
                    </span>
                    <h6 className=' ' >
                        Upcoming Holidays
                    </h6>
                    <p className='text-3xl sm:text-5xl fw-semibold ' >
                        {data && data.upcoming_holidays && data.upcoming_holidays.length}
                    </p>
                </section>
                <section onClick={() => setModalData({ data: [...data.new_hires], eventName: 'Today Hires' })}
                    className='flex flex-col gap-3 relative p-3 w-[20rem] items-center bg-slate-50 rounded cursor-pointer hover:bg-blue-50 duration-300  '>
                    <span className='absolute top-2 right-2 ' >
                        <InfoButton content="Employee joining on today. " />
                    </span>
                    <h6 className=' ' >
                        Today Hires
                    </h6>
                    <p className='text-3xl sm:text-5xl fw-semibold ' >
                        {data && data.new_hires && data.new_hires.length}
                    </p>
                </section>
                <section onClick={() => setModalData({ data: [...data.on_leaves], eventName: 'On Leave' })}
                    className='flex flex-col gap-3 relative p-3 w-[20rem] items-center bg-slate-50 rounded cursor-pointer hover:bg-blue-50 duration-300  '>
                    <span className='absolute top-2 right-2 ' >
                        <InfoButton content="Employee on leave for the one week period " />
                    </span>
                    <h6 className=' ' >
                        On Leave
                    </h6>
                    <p className='text-3xl sm:text-5xl fw-semibold ' >
                        {data && data.on_leaves && data.on_leaves.length}
                    </p>
                </section>
                {/* <section className='flex flex-col gap-3 p-3 w-[20rem] items-center bg-white rounded  '>
                    <h6 className=' ' >
                        Upcoming BirthDays
                    </h6>
                    <p className='text-3xl sm:text-5xl fw-semibold ' >
                        {data && data.today_birthdays && data.today_birthdays.length}
                    </p>
                </section> */}


            </main>
            {modalData && < Modal className=' ' centered size='xl'
                show={modalData} onHide={() => setModalData(false)} >
                <Modal.Header closeButton>
                    {modalData.eventName}
                </Modal.Header>
                <Modal.Body>
                    <main className='tablebg rounded table-responsive  ' >
                        {console.log(modalData, 'modal')
                        }
                        <table className='w-full ' >
                            <tr>
                                <th>SI No </th>
                                <th>Employee Id </th>
                                <th> Employee name </th>
                                <th> Department </th>
                                <th>Designation </th>
                            </tr>
                            {
                                modalData.data && modalData.data.map((obj, index) => (
                                    <tr>
                                        <td>{index + 1} </td>
                                        <td> {obj.employee_Id} </td>
                                        <td> {obj.full_name} </td>
                                        <td> {} </td>
                                        <td></td>
                                    </tr>
                                ))
                            }
                        </table>

                    </main>


                </Modal.Body>
            </Modal>
            }
        </div >
    )
}

export default HROverviewReport